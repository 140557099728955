<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    modelValue: any; // for two-way binding, see: https://vuejs.org/guide/components/v-model.html
    label: string;
  }>(),
  {},
);

defineEmits(["update:modelValue"]);
</script>

<template>
  <label class="cursor-pointer noSelect">
    <input
      type="checkbox"
      :checked="modelValue"
      class="accent-brand-primary rounded"
      @input="$emit('update:modelValue', $event.target.checked)"
    />
    <!-- eslint-disable vue/no-v-html -->
    <span class="ml-2" v-html="props.label" />
    <!-- eslint-enable -->
  </label>
</template>
